import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FallingLines } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { authHeader } from '../../utils';
import "./index.css";


const Challenge = () => {

    const { event_id } = useParams();

    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const url = "https://quizwin-backend.techknowgram.net/api/react/user/challengeReport/" + event_id;

        axios
            .get(url, { headers: authHeader() })
            .then(function (response) {
                const { data } = response;
                setQuestions(data);
            })
            .catch(function (error) {
            });
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    return (
        <>
            <section id="banner" class="Banner_others section_gaps result">
                <div class="container">
                    <div class="row d_flex">
                        <div class="col-lg-6 m-auto">
                            <div class="left">
                                <h1>Challenge Report</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="rules" class="section_gaps">
                <div class="container">
                    <div class="row">
                        {questions.length ? questions.map((question, i) => (
                            <div class="col-md-6">
                                <div class="single">
                                    <h4>{i + 1}. {question.question}</h4>
                                    <ul class="py-2">

                                        {Object.keys(question.options).map((key, i) => {
                                            let bgc = "";
                                            if (!question.chosen_answer) {
                                                bgc = "";
                                            } else if (key == question.answer && key == question.chosen_answer) {
                                                bgc = "green";
                                            } else if (key != question.answer && key == question.chosen_answer) {
                                                bgc = "red";
                                            } else {
                                                bgc = 'no';
                                            }

                                            return (
                                                <li class={`m-2 p-3 option ${bgc}`}>

                                                    {i == 0 && <span class="optionNumber">ক</span>}

                                                    {i == 1 && <span class="optionNumber">খ</span>}

                                                    {i == 2 && <span class="optionNumber">গ</span>}
                                                    {question.options[key]}
                                                </li>
                                            )
                                        })}



                                    </ul>

                                    <p>Correct Answer: {question.options[question.answer]}</p>

                                </div>
                            </div>
                        )) : <FallingLines
                            color="#4fa94d"
                            width="100"
                            visible={true}
                            ariaLabel='falling-lines-loading'
                        />}


                    </div>
                </div>
            </section>
        </>
    )
}

export default Challenge