import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Routes } from "react-router";
import { BrowserRouter, Navigate, Route } from "react-router-dom";

import OneSignal from "react-onesignal";

// import "./App.css";
import Layout from "./Components/Layout";
import Logout from "./Components/Logout";
import Challenge from "./Pages/Challenge";
import Edit from "./Pages/Edit";
import EventDetails from "./Pages/EventDetails";
import EventPlay from "./Pages/EventPlay";
import Events from "./Pages/Events";
import Faq from "./Pages/Faq";
import ForgetPassword from "./Pages/ForgetPassword";
import Home from "./Pages/HomePage";
import Login from "./Pages/Login";
import MyResult from "./Pages/MyResult";
import PasswordReset from "./Pages/PasswordReset";
import PointBuy from "./Pages/PointBuy";
import Prize from "./Pages/Prize";
import Profile from "./Pages/Profile";
import Result from "./Pages/Result";
import Rule from "./Pages/Rule";
import UserLogin from "./Pages/UserLogin";
import UserRegistration from "./Pages/UserRegistration";
import { authOperations } from "./state/ducs/auth";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Abouts from "./Pages/aboutUs";
import AlreadyPaid from "./Pages/AlreadyPaid";
import CancelRedirect from "./Pages/CancelRedirect";
import PaymentCancelled from "./Pages/PaymentCancelled";
import PaymentFailed from "./Pages/PaymentFailed";
import PaymentGateWayError from "./Pages/PaymentGateWayError";
import PaymentSuccess from "./Pages/PaymentSuccess";
import SubmitOtp from "./Pages/SubmitOtp";
import SuccessRedirect from "./Pages/SuccessRedirect";
import VerifyEmail from "./Pages/VerifyEmail";

const MySwal = withReactContent(Swal);

function App({ checkAuth, isAuthenticated, error }) {
  const [login, setlogin] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [golobalKeyword, setgolobalKeyword] = useState("");

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (error && error.length) {
      MySwal.fire(<p>{error}</p>);
    }
  }, [error]);

  useEffect(() => {
    OneSignal.init({ appId: "ee6c758c-6ee9-4842-8edf-c906e05c3777" });
  }, []);

  const onHandleTag = () => {};

  let routes = (
    <Routes>
      <Route
        path="/"
        element={
          <Home
            setgolobalKeyword={setgolobalKeyword}
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            t={t}
            login={login}
            setlogin={setlogin}
          />
        }
      />
      <Route path="/rule" element={<Rule />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/about" element={<Abouts />} />
      <Route path="/prize" element={<Prize />} />
      <Route path="/result" element={<Result />} />
      <Route path="/events" element={<Events />} />

      <Route path="/login" element={<Login />} />
      <Route path="/user-login" element={<UserLogin />} />
      <Route path="/user-register" element={<UserRegistration />} />
      <Route path="/varifyEmail/:email/:token" element={<VerifyEmail />} />
      <Route path="/forgetPassword" element={<ForgetPassword />} />
      <Route path="/password-reset" element={<PasswordReset />} />
      <Route path="/submit-otp" element={<SubmitOtp />} />
      <Route path="/pointbuy" element={<PointBuy />} />

      <Route path="/success/:myOrderId" element={<SuccessRedirect />} />

      <Route path="/paymentSuccess" element={<PaymentSuccess />} />

      <Route path="/paymentFailed" element={<PaymentFailed />} />

      <Route path="/cancel/:myOrderId" element={<CancelRedirect />} />

      <Route path="/paymentCancelled" element={<PaymentCancelled />} />

      <Route path="/alreadyPaid" element={<AlreadyPaid />} />

      <Route path="/paymentGateWayError" element={<PaymentGateWayError />} />

      <Route path="*" element={<Navigate to="/user-login" />} />
    </Routes>
  );

  if (isAuthenticated) {
    routes = (
      <Routes>
        <Route
          path="/"
          element={
            <Home
              setgolobalKeyword={setgolobalKeyword}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              t={t}
              login={login}
              setlogin={setlogin}
            />
          }
        />

        <Route path="/rule" element={<Rule />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/about" element={<Abouts />} />
        <Route path="/prize" element={<Prize />} />
        <Route path="/result" element={<Result />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/events" element={<Events />} />
        <Route path="/myResult" element={<MyResult />} />
        <Route path="/edit" element={<Edit />} />

        <Route path="/eventDetails/:eventId" element={<EventDetails />} />

        <Route path="/eventPlay/:eventId" element={<EventPlay />} />

        <Route path="/varifyEmail/:email/:token" element={<VerifyEmail />} />

        <Route path="/challenge/:event_id" element={<Challenge />} />

        <Route path="/user-login" element={<UserLogin />} />

        <Route path="/logout" element={<Logout />} />

        <Route path="/pointbuy" element={<PointBuy />} />

        <Route path="/success/:myOrderId" element={<SuccessRedirect />} />

        <Route path="/paymentSuccess" element={<PaymentSuccess />} />
        <Route path="/paymentFailed" element={<PaymentFailed />} />

        <Route path="/cancel/:myOrderId" element={<CancelRedirect />} />

        <Route path="/paymentCancelled" element={<PaymentCancelled />} />

        <Route path="/alreadyPaid" element={<AlreadyPaid />} />

        <Route path="/paymentGateWayError" element={<PaymentGateWayError />} />

        <Route path="*" element={<Navigate to="/user-login" />} />
      </Routes>
    );
  }

  return (
    <div>
      <BrowserRouter>
        <Layout
          login={login}
          setlogin={setlogin}
          t={t}
          changeLn={changeLanguage}
          isAuthenticated={isAuthenticated}
        >
          {routes}
        </Layout>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    error: state.auth.error,
  };
};

const mapDispatchToProps = {
  checkAuth: authOperations.authStateCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
