import axios from "axios";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "./index.css";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const UserRegistration = () => {
  const phoneRegExp = /^(?:\+?88)?01[13-9]\d{8}$/; //bd phone regex

  const registrationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    username: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    mobile_number: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid"
    ),
    password: Yup.string()
      .min(6, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),

    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const handleRegistrationSubmit = (values, actions) => {
    //network request here
    const regPostObj = {
      name: values.name,
      username: values.username,
      email: values.email,
      mobile_number: values.mobile_number,
      password: values.password,
      // password_confirmation: values.password_confirmation
    };

    const regUrl =
      "https://quizwin-backend.techknowgram.net/api/react/register";

    axios
      .post(regUrl, regPostObj)
      .then((response) => {
        if (response.status === 200) {
          actions.resetForm();
          // alert("Registration successful");
          MySwal.fire(<p>Registration successful</p>)
          navigate("/user-login");
        }
      })
      .catch((error) => {
        if (error.response.data.message.length) {
          return MySwal.fire(<p>{error.response.data.message}</p>);
        }
      });
  };

  const navigate = useNavigate();

  const goToLogin = (e) => {
    e.preventDefault();
    navigate("/user-login");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section id="login">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-8 m-auto">
              <Formik
                initialValues={{
                  name: "",
                  username: "",
                  email: "",
                  mobile_number: "",
                  password: "",
                  password_confirmation: "",
                }}
                validationSchema={registrationSchema}
                onSubmit={handleRegistrationSubmit}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* @csrf */}

                    <div class="login_coltent">
                      <div class="img">
                        <img
                          src="https://quizwin.xyz/admin/media/logo/quizwin logo.png"
                          alt=""
                        />
                      </div>

                      <h2>Register</h2>

                      <div class="custome_input">
                        <input
                          type="text"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          required
                          placeholder="আপনার নাম"
                        />

                        {touched.name && errors.name && (
                          <div style={{ color: "red" }}>{errors.name}</div>
                        )}
                      </div>

                      <div class="custome_input">
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          required
                          placeholder="আপনার ইমেইল"
                        />
                        {touched.email && errors.email && (
                          <div style={{ color: "red" }}>{errors.email}</div>
                        )}
                      </div>

                      <div class="custome_input">
                        <input
                          type="text"
                          name="mobile_number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mobile_number}
                          required
                          placeholder="আপনার মোবাইল নম্বর"
                        />

                        {touched.mobile_number && errors.mobile_number && (
                          <div style={{ color: "red" }}>
                            {errors.mobile_number}
                          </div>
                        )}
                      </div>

                      <div class="custome_input">
                        <input
                          type="text"
                          name="username"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.username}
                          placeholder="আপনার ইউজার নাম প্রবেশ করুন"
                          required
                        />

                        {touched.username && errors.username && (
                          <div style={{ color: "red" }}>{errors.username}</div>
                        )}
                      </div>

                      <div class="custome_input">
                        <input
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          placeholder="আপনার পাসওয়ার্ড"
                          required
                        />
                        {touched.password && errors.password && (
                          <div style={{ color: "red" }}>{errors.password}</div>
                        )}
                      </div>

                      <div class="custome_input">
                        <input
                          type="password"
                          name="password_confirmation"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password_confirmation}
                          placeholder="আপনার পাসওয়ার্ড নিশ্চিতকরণ"
                          required
                        />
                        {touched.password_confirmation &&
                          errors.password_confirmation && (
                            <div style={{ color: "red" }}>
                              {errors.password_confirmation}
                            </div>
                          )}
                      </div>

                      <div class="custome_input">
                        <button type="submit"> SUBMIT </button>
                        <h3>
                          <a
                            onClick={goToLogin}
                            href="{{ route('direct-login') }}"
                          >
                            লগইন করুন
                          </a>
                        </h3>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserRegistration;
