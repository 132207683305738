import axios from "axios";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";

const MySwal = withReactContent(Swal);

const ForgetPassword = () => {
  const navigate = useNavigate();


  const handleEmailSubmit = (values, actions) => {
    //network request here
    const PostEmail = {
      email: values.email,
    };

    const url =
      "https://quizwin-backend.techknowgram.net/api/react/sendOtpToEmail";

    axios
      .post(url, PostEmail)
      .then((response) => {
        if (response.status === 200) {
          actions.resetForm();
          MySwal.fire(<p>{response.data.message}</p>)
          navigate("/submit-otp");
        }
      })
      .catch((error) => {
        MySwal.fire(<p>{error.response.data.message}</p>)
      });
  };
  const emailValidationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const goToLogin=(e)=> {
    e.preventDefault();
    navigate("/user-login");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section id="login">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-8 m-auto">
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={emailValidationSchema}
                onSubmit={handleEmailSubmit}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div class="login_coltent">
                      <div class="img">
                        <img
                          src="https://quizwin.xyz/admin/media/logo/quizwin logo.png"
                          alt=""
                        />
                      </div>

                      <h2>Forgot Password</h2>
                      <div class="custome_input">
                      <input
                        type="text"
                        name="email"
                        placeholder="আপনার ইমেইল এড্রেস টি প্রবেশ করুন"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {touched.email && errors.email && (
                        <div style={{ color: "red" }}>{errors.email}</div>
                      )}
                      </div>
                      <div class="custome_input">
                        <button type="submit"> SUBMIT </button>
                        <h3>
                          <a
                            href="/user-login"
                            onClick={goToLogin}
                          >
                            লগইন করুন
                          </a>
                        </h3>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};



export default ForgetPassword;
