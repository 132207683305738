import { Formik } from "formik";
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import './index.css';


import { authOperations } from "../../state/ducs/auth";


const UserLogin = ({
    setlogin,
    loading,
    error,
    isAuthenticated,
    authRedirectPath,
    signIn,
    setAuthRedirectPath,
}) => {
    const loginSchema = Yup.object().shape({
        username: Yup.string().required("Required"),
        password: Yup.string()
            .min(6, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
    });

    const handleLoginSubmit = (values) => {
        signIn(values.username, values.password);
    };

    let authRedirect = null;
    if (isAuthenticated) {
        authRedirect = <Navigate to={authRedirectPath} />;
    }

    const navigate= useNavigate();

    const goToRegister=(e)=> {
        e.preventDefault();
        navigate("/user-register");
    }

    useEffect(() => {
        setAuthRedirectPath("/");
    }, []);

    const goToForgetPassword = () => {
        navigate("/forgetPassword");
      };

      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            {authRedirect}
            <section id="login">

                <div className="container">

                    <div className="row">

                        <div className="col-lg-6 col-md-8 m-auto">
                           

                            <Formik
                                initialValues={{
                                    username: "",
                                    password: "",
                                }}
                                validationSchema={loginSchema}
                                onSubmit={handleLoginSubmit}
                            >
                                {({
                                    errors,
                                    touched,
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                }) => (
                                    <form onSubmit={handleSubmit}>


                                        <div className="login_coltent">

                                            <div className="img">
                                                <img src="https://quizwin.xyz/admin/media/logo/quizwin logo.png" alt="" />
                                            </div>

                                            <h2>Login</h2>

                                            <div className="custome_input">
                                                <input type="text" name="username"

                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.username}
                                                    placeholder="আপনার ইউজার নাম প্রবেশ করুন" required />

                                                {touched.username && errors.username && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.username}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="custome_input">
                                                <input type="password" name="password"
                                                 onChange={handleChange}
                                                 onBlur={handleBlur}
                                                 value={values.password}
                                                 placeholder="আপনার পাসওয়ার্ড প্রবেশ করুন" required />

                                                {touched.password && errors.password && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.password}
                                                    </div>
                                                )}
                                            </div>
                                            <a className="password" onClick={goToForgetPassword}>Forgot Password</a>
                                            <div className="custome_input">
                                                <button type="submit"> Login </button>
                                                <h3><a onClick={goToRegister} href="/user-register">কুইজে অংশগ্রহন করতে রেজিস্ট্রেশন সম্পন্ন
                                                    করুনঃ</a></h3>
                                            </div>

                                        </div>

                                    </form>

                                )}
                            </Formik>

                        </div>

                    </div>

                </div>

            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
    };
};

const mapDispatchToProps = {
    signIn: authOperations.auth,
    setAuthRedirectPath: authOperations.setAuthRedirectPath,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);