import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import "./index.css";

import { authOperations } from "../../../state/ducs/auth";

const Header = ({
  loading,
  error,
  isAuthenticated,
  userInfo,
  authRedirectPath,
  signIn,
  setAuthRedirectPath,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrolled]);

  useEffect(() => {}, []);

  const goToProfile = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/profile");
  };

  const goToHome = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/");
  };

  const gotoRule = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/rule");
  };

  const gotoResult = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/result");
  };

  const goToFaq = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/faq");
  };

  const goToPrize = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/prize");
  };

  const logOut = (e) => {
    e.stopPropagation();
    navigate("/logout");
  };
  const goToAbout = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/about");
  };


  return (
    <>
      {location.pathname == "/" || location.pathname == '/result' || location.pathname == '/user-login' || location.pathname == '/user-register' || location.pathname == '/forgetPassword' || location.pathname == '/password-reset' || location.pathname == '/submit-otp' || location.pathname == '/varifyEmail' ? (
        ""
      ) : (
        <div
          style={{ height: "138px", background: "rgba(92, 61, 141, 0.7)" }}
        ></div>
      )}

      <nav className={`navbar navbar-expand-lg ${scrolled ? "scrolled" : ""}`}>
        <div className="container h-100">
          <a onClick={goToHome} className="navbar-brand h-100" href="#">
            <img
              height="80px"
              className="emon h-100"
              src="https://quizwin.xyz/admin/media/logo/quizwin logo.png"
              alt=""
            />
          </a>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto">
              <li className="nav-item">
                <a
                  onClick={goToHome}
                  className={`nav-link ${
                    location.pathname == "/" ? "active" : ""
                  }`}
                  aria-current="page"
                  href="/"
                >
                  হোম{" "}
                </a>
              </li>
              <li className="nav-item">
                <a
                  onClick={gotoRule}
                  className={`nav-link ${
                    location.pathname == "/rule" ? "active" : ""
                  }`}
                  aria-current="page"
                  href="/rule"
                >
                  নিয়মাবলী
                </a>
              </li>
              <li className="nav-item">
                <a
                  onClick={gotoResult}
                  className={`nav-link ${
                    location.pathname == "/result" ? "active" : ""
                  }`}
                  aria-current="page"
                  href="/result"
                >
                  ফলাফল
                </a>
              </li>
              <li className="nav-item">
                <a
                  onClick={goToFaq}
                  className={`nav-link ${
                    location.pathname == "/faq" ? "active" : ""
                  }`}
                  aria-current="page"
                  href="/faq"
                >
                  FAQ{" "}
                </a>
              </li>

              <li className="nav-item">
                <a
                  onClick={goToPrize}
                  className={`nav-link ${
                    location.pathname == "/prize" ? "active" : ""
                  }`}
                  aria-current="page"
                  href="/prize"
                >
                  {" "}
                  পুরস্কার{" "}
                </a>
              </li>
            </ul>

            {isAuthenticated && (
              <>
                <form className="d-flex">
                  <div
                    className="profile-avatar"
                    onClick={goToProfile}
                    href="/profile"
                  >
                    {userInfo.full_user_photo_path ? (
                      <img
                        role="button"
                        className="rounded-circle cursor-pointer"
                        height={"70"}
                        width={"70"}
                        src={userInfo.full_user_photo_path}
                        alt=""
                      />
                    ) : (
                      <span role="button">
                        <i
                          className="fa fa-user"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </span>
                    )}

                    <div className="logoutButton" style={{ marginLeft: "2px" }}>
                      <div className="item" onClick={logOut}>
                        Logout
                      </div>
                    </div>
                  </div>
                </form>
              </>
            )}

            {!isAuthenticated && (
              <>
                <form className="d-flex">
                  <a href="/user-login">Login</a>
                </form>

                <form className="d-flex" style={{ marginLeft: "2px" }}>
                  <a href="/user-register">Registration</a>
                </form>
              </>
            )}
          </div>
          <div className="mobile_menu">
            <button
              className="btn"
              type="button"
              onClick={handleShow}
              aria-controls="offcanvasExample"
            >
              <img
                src="http://quizwin.xyz/admin/frontend/images/menu.png"
                alt=""
              />
            </button>

            <Offcanvas
              show={show}
              onHide={handleClose}
              responsive="lg"
              placement={"end"}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>MENU</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav m-auto">
                    <li className="nav-item">
                      <a
                        onClick={goToHome}
                        className={`nav-link ${
                          location.pathname == "/" ? "active" : ""
                        }`}
                        aria-current="page"
                        href="/"
                      >
                        হোম{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        onClick={gotoRule}
                        className={`nav-link ${
                          location.pathname == "/rule" ? "active" : ""
                        }`}
                        aria-current="rule"
                        href="/rule"
                      >
                        নিয়মাবলী
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        onClick={gotoResult}
                        className={`nav-link ${
                          location.pathname == "/result" ? "active" : ""
                        }`}
                        aria-current="page"
                        href="/result"
                      >
                        ফলাফল
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        onClick={goToPrize}
                        className={`nav-link ${
                          location.pathname == "/prize" ? "active" : ""
                        }`}
                        aria-current="page"
                        href="/prize"
                      >
                        {" "}
                        পুরস্কার{" "}
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        onClick={goToFaq}
                        className={`nav-link ${
                          location.pathname == "/faq" ? "active" : ""
                        }`}
                        aria-current="page"
                        href="/faq"
                      >
                        FAQ{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link "
                        aria-current="page"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        যোগাযোগ
                      </a>
                    </li>
                  </ul>

                  {isAuthenticated && (
                    <>
                      <form className="d-flex">
                        <div onClick={goToProfile} href="/profile">
                          {userInfo.full_user_photo_path ? (
                            <img
                              className="rounded-circle"
                              height={"70"}
                              width={"70"}
                              src={userInfo.full_user_photo_path}
                              alt=""
                            />
                          ) : (
                            <span>
                              <i
                                className="fa fa-user"
                                style={{ fontSize: "30px" }}
                              ></i>
                            </span>
                          )}
                        </div>
                      </form>

                      <form className="d-flex" style={{ marginTop: "10px" }}>
                        <a href="/logout">Logout</a>
                      </form>
                    </>
                  )}

                  {!isAuthenticated && (
                    <>
                      <form className="d-flex">
                        <a href="/user-login">Login</a>
                      </form>

                      <form className="d-flex" style={{ marginTop: "2px" }}>
                        <a href="/user-register">Registration</a>
                      </form>
                    </>
                  )}

                  <div className="copy_right">
                    <p>
                      &copy; QuizWin All Rights Reserved <br /> Powered by
                      TechKnowGram Limited.
                    </p>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = {
  signIn: authOperations.auth,
  setAuthRedirectPath: authOperations.setAuthRedirectPath,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
