import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { FallingLines } from 'react-loader-spinner';
import './index.css';

import { useNavigate } from 'react-router-dom';

import { authHeader } from '../../utils';

const Events = () => {
    const [events, setEvents] = useState([]);

    const navigate = useNavigate();
    const sectionRef = useRef(null);

    useEffect(() => {


        const url = "https://quizwin-backend.techknowgram.net/api/react/events";

        axios
            .get(url, { headers: authHeader() })
            .then(function (response) {
                const { data } = response;
                setEvents(data);
            })
            .catch(function (error) {
            });
    }, []);


    const goToEventDetails = (e, eventId) => {
        e.preventDefault();
        navigate("/eventDetails/" + eventId, {state: { IsUpcoming: false }});
    }

    const goToUpComingEventDetails = (e, eventId) => {
        e.preventDefault();
        navigate("/eventDetails/" + eventId, {state: { IsUpcoming: true }});
    }

    const goToResult = (e) => {
        e.preventDefault();
        navigate("/result");
    }

    useEffect(() => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);

    return (
        <>
            <section id="banner" class="Banner_others section_gaps faq">
                <div class="container">
                    <div class="row d_flex">
                        <div class="col-lg-6 m-auto">
                            <div class="left">
                                <h1>কুইজে অংশগ্রহন করুন</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ height: "50px" }} ref={sectionRef}></section>
            <section id="attend_quiz" class="section_gaps">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-md-6 col-12 m-auto">
                            <div class="attend_quiz_item">
                                <div class="img">
                                    <img class="img-fluid w-sm-50 w-md-100" src="https://quizwin.xyz/admin/media/logo/quizwin logo.png" alt="" />
                                </div>
                                <div class="text">
                                    <h2>চলমান প্রতিযোগিতা</h2>

                                    <ul>

                                        {events && events.length ? events.filter(item => item.event_status == 'live').reverse().map((item, i) => {

                                            return <li class={`nextStep ${i == 0 ? "current" : ""} mb-3`} data-id={item.id}>
                                                <button class="button-72" role="button" onClick={(ev) => goToEventDetails(ev, item.id)} href="#">
                                                    <i class="fa fa-link mx-2"></i>
                                                    {item.event_name}
                                                </button>
                                                <p>(will continue from {new Date(item.event_start).getDate()} {new Date(item.event_start).toLocaleString('default', { month: 'long' })} to {new Date(item.event_end).getDate()} {new Date(item.event_end).toLocaleString('default', { month: 'long' })})</p>
                                            </li>
                                        }) : <FallingLines
                                            color="#4fa94d"
                                            width="100"
                                            visible={true}
                                            ariaLabel='falling-lines-loading'
                                        />}

                                    </ul>
                                </div>


                                <div class="text my-5">

                                    <h2>পরবর্তী প্রতিযোগিতা</h2>

                                    <h4 style={{ color: "#000" }}>কুইজ উইন এর ওয়েবসাইট ও ফেসবুক পেইজে চোখ রাখুন এবং সংযুক্ত থাকুন</h4>
                                    <ul>
                                        {events && events.length ? events.filter(item => item.event_status == 'upcoming').reverse().map((item, i) => {

                                            return <li class={`nextStep ${i == 0 ? "current" : ""}`} data-id={item.id}>
                                                <button class="button-72" role="button" onClick={(ev) => goToUpComingEventDetails(ev, item.id)} href="#">
                                                    <i class="fa fa-link mx-2"></i>
                                                    {item.event_name}
                                                </button>
                                                <p>(will continue from {new Date(item.event_start).getDate()} {new Date(item.event_start).toLocaleString('default', { month: 'long' })} to {new Date(item.event_end).getDate()} {new Date(item.event_end).toLocaleString('default', { month: 'long' })})</p>
                                            </li>
                                        }) : <FallingLines
                                            color="#4fa94d"
                                            width="100"
                                            visible={true}
                                            ariaLabel='falling-lines-loading'
                                        />}
                                    </ul>
                                </div>

                                <div class="text">
                                    <h2>পূর্বের প্রতিযোগিতার ফলাফল</h2>

                                    <ul>


                                        {events && events.length ? events.filter(item => item.event_status == 'finished').map((item, i) => {

                                            if (i == 0 || item.event_name == 'For App') {
                                                return;
                                            }
                                            return <li class={`nextStep ${i == 0 ? "current" : ""}`} data-id={item.id}>
                                                <button class="button-73 my-3" role="button" onClick={goToResult} href="#">
                                                    <i class="fa fa-trophy mx-2"></i>
                                                    {item.event_name}
                                                </button>
                                            </li>
                                        }) : <FallingLines
                                            color="#4fa94d"
                                            width="100"
                                            visible={true}
                                            ariaLabel='falling-lines-loading'
                                        />}

                                    </ul>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Events;