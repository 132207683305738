import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FallingLines } from 'react-loader-spinner';


const Rule = () => {
  const [rules, setRules] = useState([]);
  const sectionRef = useRef(null)

  useEffect(() => {
    // fetch("https://quizwin-backend.techknowgram.net/api/react/events")
    //     .then((response) => response.json())
    //     .then((data) => setEvents(data));

    const url = "https://quizwin-backend.techknowgram.net/api/react/rules";

    axios
      .get(url)
      .then(function (response) {
        const { data } = response;
        setRules(data);
      })
      .catch(function (error) {
      });
  }, []);


  useEffect(() => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);


  return (
    <>
      <section id="banner" class="Banner_others section_gaps">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>নিয়মাবলী</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="rules" class="section_gaps" ref={sectionRef}>
        <div class="container">
          <div class="row">
            <div class="col-lg-8 mb-4 mx-auto">
              <div class="rules_content">
                <h3>নিয়মাবলি</h3>
                <ul>
                  {rules && rules.length ? (
                    rules.filter(rule=> rule.status==1).map((item, i) => (
                      <li
                        class={`nextStep ${i == 0 ? "current" : ""}`}
                        data-id={item.id}
                      >
                        <b> {item.rule} </b>
                      </li>
                    ))
                  ) : (
                    <FallingLines
                      color="#4fa94d"
                      width="100"
                      visible={true}
                      ariaLabel='falling-lines-loading'
                    />
                  )}

                </ul>
              </div>
            </div>
            {/* <div class="col-lg-4 mb-4">
              <div class="rules_content">
                <h3>BASIS SoftExpo 2023 কুইজ নিয়মাবলি</h3>
                <ul>
                  {rules && rules.length ? (
                    rules.filter(rule=> rule.status==2).map((item, i) => (
                      <li
                        class={`nextStep ${i == 0 ? "current" : ""}`}
                        data-id={item.id}
                      >
                        <b> {item.rule} </b>
                      </li>
                    ))
                  ) : (
                    <FallingLines
                      color="#4fa94d"
                      width="100"
                      visible={true}
                      ariaLabel='falling-lines-loading'
                    />
                  )}

                </ul>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="rules_content">
                <h3>মাতৃভাষা কুইজ 2023 নিয়মাবলি</h3>
                <ul>
                  {rules && rules.length ? (
                    rules.filter(rule=>rule.status==3).map((item, i) => (
                      <li
                        class={`nextStep ${i == 0 ? "current" : ""}`}
                        data-id={item.id}
                      >
                        <b> {item.rule} </b>
                      </li>
                    ))
                  ) : (
                    <FallingLines
                      color="#4fa94d"
                      width="100"
                      visible={true}
                      ariaLabel='falling-lines-loading'
                    />
                  )}

                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Rule;
