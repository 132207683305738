import React from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentGateWayError = () => {
    const navigate= useNavigate();

    const goHome=(e)=> {
        e.preventDefault();
        navigate("/");

    }
    return (
        <section id="login">

            <div class="container">

                <div class="row">

                    <div class="col-lg-6 col-md-8 col-sm-8 m-auto">

                        <div class="login_coltent">

                            <div class="img">
                                <img src="https://quizwin.xyz/admin/media/logo/quizwin logo.png" alt="" />
                            </div>

                            <h2>Payment Gateway Error</h2>


                            <div class="custome_input">
                                <a onClick={goHome} href="/"><button type="button" class="resend"> PLay
                                    Now </button></a>

                            </div>

                        </div>



                    </div>

                </div>

            </div>

        </section>
    )
}

export default PaymentGateWayError;