import axios from "axios";
import { Formik } from "formik";
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import "./index.css";


import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const PasswordReset = () => {

  const navigate = useNavigate();

  const { state } = useLocation();


  const resetSchema = Yup.object().shape({

    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .min(6, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),

    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const handleResetSubmit = (values, actions) => {
    //network request here
    const regPostObj = {
      password: values.password,
      otp: state,
      email: values.email
      // password_confirmation: values.password_confirmation
    };

    const regUrl =
      "https://quizwin-backend.techknowgram.net/api/react/resetPassword";

    axios
      .post(regUrl, regPostObj)
      .then((response) => {
        if (response.status === 200) {
          actions.resetForm();
          // alert("Registration successful");
          MySwal.fire(<p>{response.data.message}</p>)
          navigate("/user-login");
        }
      })
      .catch((error) => {
        if (error.response.data.message.length) {
          return MySwal.fire(<p>{error.response.data.message}</p>);
        }
      });
  };

  const goToLogin = (e) => {
    e.preventDefault();
    navigate('/user-login');
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <section id="login">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-8 m-auto">
              <Formik
                initialValues={{
                  password: "",
                  password_confirmation: "",
                  email: "",
                }}
                validationSchema={resetSchema}
                onSubmit={handleResetSubmit}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* @csrf */}

                    <div class="login_coltent">
                      <div class="img">
                        <img
                          src="https://quizwin.xyz/admin/media/logo/quizwin logo.png"
                          alt=""
                        />
                      </div>

                      <h2>Password Reset</h2>

                      
                      <div class="custome_input">
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          required
                          placeholder="আপনার ইমেইল"
                        />
                        {touched.email && errors.email && (
                          <div style={{ color: "red" }}>{errors.email}</div>
                        )}
                      </div>

                      <div class="custome_input">
                        <input
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          placeholder="আপনার নতুন পাসওয়ার্ড"
                          required
                        />
                        {touched.password && errors.password && (
                          <div style={{ color: "red" }}>{errors.password}</div>
                        )}
                      </div>

                      <div class="custome_input">
                        <input
                          type="password"
                          name="password_confirmation"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password_confirmation}
                          placeholder="আপনার নতুন পাসওয়ার্ড নিশ্চিতকরণ"
                          required
                        />
                        {touched.password_confirmation &&
                          errors.password_confirmation && (
                            <div style={{ color: "red" }}>
                              {errors.password_confirmation}
                            </div>
                          )}
                      </div>

                      <div class="custome_input">
                        <button type="submit"> SUBMIT </button>
                        <h3>
                          <a
                            onClick={goToLogin}
                            href="/user-login"
                          >
                            লগইন করুন
                          </a>
                        </h3>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PasswordReset