import axios from "axios";
import React, { useEffect, useState } from 'react';
import { FallingLines } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import withReactContent from "sweetalert2-react-content";
import { authHeader } from "../../utils";


import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);



const SuccessRedirect = () => {
  //get parameter from success redirect url
  //send a request to verify the payment if succeeded
  //after getting the response from server
  //check if successfully verified or not
  //if success, then redirect to payment success page

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate= useNavigate();

  
  const location = useLocation();
  let { myOrderId } = useParams();
  const queryParams = new URLSearchParams(location.search);

  // Get the value of a specific query parameter
  const spOrderId= queryParams.get("order_id");

  console.log("location=", location);

  // Get all query parameters as an object
  const paramsObj = Object.fromEntries(queryParams.entries());

  const buyPointHandler = () => {
    const url =
      "https://quizwin-backend.techknowgram.net/api/react/coins/confirmPurchase";
    const postObj = {
      coin_transaction_id: myOrderId,
    };

    setIsSubmitting(true);

    
    axios
      .post(url, postObj, { headers: authHeader() })
      .then(function (response) {
        const { data } = response;

        setIsSubmitting(false);

        console.log("response", data);
        navigate("/paymentSuccess");
      })
      .catch(function (error) {
        console.log(error.response.data.message);
        // MySwal.fire(<p>{error.response.data.message}</p>);
        setIsSubmitting(false);
        navigate("/paymentFailed")
      });
  };

  useEffect(() => {
    buyPointHandler();
  }, [])
  
  return (
    <>
      <section id="banner" class="Banner_others section_gaps faq">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>পয়েন্ট কিনুন</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="attend_quiz" class="section_gaps buy_coin">
        <div class="container">
          <div class="row">
           {isSubmitting && (
              <FallingLines
                color="#4fa94d"
                width="100"
                visible={true}
                ariaLabel="falling-lines-loading"
              />
            )}
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  )
}

export default SuccessRedirect