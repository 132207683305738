import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { authOperations } from "../../state/ducs/auth";

const Logout = ({ logout, removeUser }) => {
  useEffect(() => {
    logout();
    removeUser();
    localStorage.removeItem("userWelcomed");
  }, []);

  return <Navigate to={"/"} />;
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  logout: authOperations.logout,
  removeUser: authOperations.removeAuthUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
