import axios from "axios";
import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import "./index.css";

import { useNavigate } from "react-router-dom";

import { authHeader } from "../../utils";

const Result = () => {
  const [events, setEvents] = useState([]);

  const [winners, setWinners] = useState([]);

  const [event, setEvent] = useState({});

  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const url = "https://quizwin-backend.techknowgram.net/api/react/events";

    axios
      .get(url, { headers: authHeader() })
      .then(function (response) {
        const { data } = response;
        setEvents(data);
      })
      .catch(function (error) {});
  }, []);

  const handleChange = (e) => {
    const eventId = e.target.value.split("/")[0];

    const event = events.find((event) => event.id == eventId);

    setEvent(event);

    setSubmitting(true);

    const url = "https://quizwin-backend.techknowgram.net/api/react/events/";

    axios
      .get(url + eventId + "/participants", { headers: authHeader() })
      .then(function (response) {
        const { data } = response;
        setWinners(data);

        setSubmitting(false);
      })
      .catch(function (error) {});
  };

  const goToLiveEvent = () => {
    navigate("/eventDetails/" + event.id);
  };

  const diffOptionStyle = {
    backgroundColor: "green",
    color: "#fff",
  };

  const diffOptionStyleUnlive = {
    backgroundColor: "#fff",
    color: "#000",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section style={{ marginTop: "5rem" }} id="rules" class="section_gaps">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 m-auto">
              <div class="row">
                <div class="col-md-3 m-auto">
                  <form action="{{ route('result') }}" method="GET">
                    <input type="hidden" name="resultshow" value="1" />
                    <label for="">Select Event</label>
                    <select
                      onChange={handleChange}
                      name="event"
                      class="form-control"
                    >
                      <option value="" disabled selected>
                        Select One
                      </option>

                      {events.length &&
                        events
                          .filter(
                            (event) => event.event_status != "upcoming"
                            && event.show_resut==1
                            // && event.event_name !="BASIS SoftExpo 2023 Quiz"
                          )
                          .map((ev, i) => {
                            if (ev.event_name == "For App") {
                              return;
                            }
                            return (
                              <option
                                style={
                                  ev.event_status == "live"
                                    ? diffOptionStyle
                                    : diffOptionStyleUnlive
                                }
                                value={ev.id + "/" + (i + 1)}
                              >
                                {ev.event_name}&nbsp; &nbsp;( &nbsp;
                                {ev.event_status == "live"
                                  ? "Live Event"
                                  : "Past Event"}
                                &nbsp; )
                              </option>
                            );
                          })}
                    </select>
                  </form>
                </div>
              </div>
              <div class="result_header my-2">
                <h3>
                  {" "}
                  লিডারবোর্ড
                  {/* {winners.length && replaceNumbers(winners.length.toString())} জন  */}
                </h3>
              </div>
            </div>
          </div>
          <div class="result_content">
            <div class="row">
              {event.event_status == "live" ? (
                <h4 className="text-center mb-5">
                  Event is live{" "}
                  <button
                    class="button-34"
                    role="button"
                    onClick={goToLiveEvent}
                  >
                    Play Now
                  </button>
                </h4>
              ) : (
                ""
              )}
              {winners.length ? <h4 className="text-center mb-5">Top {winners.length} Performers</h4>:""}
              {winners.length
                ? winners.slice(0, event.result_show_count).map((winner, i) => (
                    <div class="col-lg-2 col-md-3 col-sm-6 col-6">
                      <div class="result_item">
                        <div class="img">
                          {/* {{-- < img src="{{ asset('media/resultwinner/man.png') }}" alt=""> --}} */}

                          {winner.user.full_user_photo_path && (
                            <img
                              src={winner.user.full_user_photo_path}
                              alt="..."
                              class="img-thumbnail"
                            />
                          )}
                          {!winner.user.full_user_photo_path && (
                            <i
                              style={{ fontSize: "130px" }}
                              class="fa fa-user-circle"
                            ></i>
                          )}
                        </div>
                        <div class="text">
                          <h3>
                            {(winner.user && winner.user.name) ||
                              (winner.user && winner.user.username)}
                          </h3>
                          <p>Score : {winner.total_mark}</p>
                          <p>Position: {i + 1}</p>
                          <p>Time : {winner.total_time} (seconds)</p>
                        </div>
                      </div>
                    </div>
                  ))
                : ""}

              {!winners.length &&
              !submitting &&
              event.event_status != "live" &&
              event.event_name ? (
                <h4 className="text-center">No Results to Show</h4>
              ) : (
                ""
              )}

              {!winners.length &&
              submitting &&
              event.event_status != "live" &&
              event.event_name ? (
                <FallingLines
                  color="#4fa94d"
                  width="100"
                  visible={true}
                  ariaLabel="falling-lines-loading"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Result;
