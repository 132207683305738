import axios from "axios";
import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { authOperations } from "../../state/ducs/auth";
import { authHeader } from "../../utils";

const MySwal = withReactContent(Swal);

const VerifyEmail = ({ checkUser }) => {
  const navigate = useNavigate();
  const { email, token } = useParams();

  useEffect(() => {
    //network request here
    const verifyPostObj = {
      email: email,
      token: token,
    };

    const url =
      "https://quizwin-backend.techknowgram.net/api/react/verifyEmail";

    axios
      .post(url, verifyPostObj, { headers: authHeader() })
      .then((response) => {
        if (response.status === 200) {
          checkUser();
          MySwal.fire(<p>Verification successful</p>);
          navigate("/profile");
        }
      })
      .catch((error) => {

        if (error.response.status === 405) {
          MySwal.fire(<p>Already verified</p>);
          navigate("/profile");
        } else {
          MySwal.fire(<p>Verification failed</p>);
          navigate("/profile");
        }
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <h1>Checking...</h1>
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  checkUser: authOperations.authUserCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
