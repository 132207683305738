import React, { useEffect } from "react";
import { FallingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const CancelRedirect = () => {
  //useEffect here
  //get the parameter from cancel redirect url
  //send a request to the server with the parameter that the payment has been cancelled
  //then after getting the response, redirect to payment cancelled page

  const navigate= useNavigate();

  useEffect(() => {
    navigate("/paymentCancelled");
  }, []);

  return (
    <>
      <section id="banner" class="Banner_others section_gaps faq">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>পয়েন্ট কিনুন</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="attend_quiz" class="section_gaps buy_coin">
        <div class="container">
          <div class="row">
            <FallingLines
              color="#4fa94d"
              width="100"
              visible={true}
              ariaLabel="falling-lines-loading"
            />
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
};

export default CancelRedirect;
