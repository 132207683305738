import axios from "axios";
import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import logo from "../../images/logo.png";
import { authHeader } from "../../utils";
import "./index.css";


const MySwal = withReactContent(Swal);
const PointBuy = () => {
  const [pointBuy, setPointBuy] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const url = "https://quizwin-backend.techknowgram.net/api/react/coins";

    setIsSubmitting(true);
    axios
      .get(url)
      .then(function (response) {
        console.log(response);
        const { data } = response;
        setPointBuy(data);
        setIsSubmitting(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsSubmitting(false);
      });
  }, []);

  const buyPointHandler = (e) => {
    e.preventDefault();
    const serviceName = e.target.getAttribute("data-service");
    const url =
      "https://quizwin-backend.techknowgram.net/api/react/coins/purchase";
    const postObj = {
      service_name: serviceName,
    };

    setIsSubmitting(true);
    axios
      .post(url, postObj, { headers: authHeader() })
      .then(function (response) {
        const { message_id } = response;
        const { service_name } = response;
        const { data } = response;

        setIsSubmitting(false);

        console.log("response", data);

        window.open(data.data.checkout_url);

        //navigate to result page here
        // navigate("/varify", {
        //   state: data,
        // });
      })
      .catch(function (error) {
        console.log(error.response.data.message);
        MySwal.fire(<p>{error.response.data.message}</p>);
        setIsSubmitting(false);
      });
  };

  console.log("pointBuy", pointBuy);

  return (
    <>
      <section id="banner" class="Banner_others section_gaps faq">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>পয়েন্ট কিনুন</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="attend_quiz" class="section_gaps buy_coin">
        <div class="container">
          <div class="row">
            {pointBuy && pointBuy.length && !isSubmitting ? (
              pointBuy.map((item, i) => (
                <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                  <div class="attend_quiz_item">
                    <div class="img">
                      <img src={logo} alt="" />
                    </div>
                    <div class="coin_img">
                      <img
                        src={"https://quizwin.xyz/admin/" + item.image}
                        alt=""
                      />
                    </div>
                    <div class="text">
                      <h4>{item.total_coin} পয়েন্ট</h4>
                      <h5>৳ {item.price}</h5>

                      <div class="start">
                        <a
                          onClick={buyPointHandler}
                          href=""
                          data-service={item.service_name}
                        >
                          পয়েন্ট কিনুন
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <FallingLines
                color="#4fa94d"
                width="100"
                visible={true}
                ariaLabel="falling-lines-loading"
              />
            )}
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
};

export default PointBuy;